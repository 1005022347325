<template>
  <div class="setke-continer">
    <div class="setke-center">
      <div class="call">
        <div class="text">固定取件时间:</div>
        <div class="inp">
          <el-time-picker
            @change="time"
            is-range
            v-model="value1"
            format="HH-mm-ss"
            value-format="HH-mm-ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
        </div>
        <div class="ico">
          <i @click="add" class="el-icon-circle-plus-outline"></i>
        </div>
      </div>
      <div class="end">
        <div class="end-one" v-for="item in list" :key="item.id">
          <div class="list">
            <div class="list-left">
              <img src="../../assets/images/icon_shijian_01@2x.png" alt="" />
            </div>
            <div class="list-center">
              {{
                item.startHour +
                "-" +
                item.startMinute +
                "-" +
                item.startSecond +
                "——" +
                item.endHour +
                "-" +
                item.endMinute +
                "-" +
                item.endSecond
              }}
            </div>
          </div>
          <div class="his">
            <i @click="dele(item.id)" class="el-icon-remove-outline"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AddOrEdit, GetList, Delete } from "../../api/setkd";
export default {
  data() {
    return {
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      oldH: Number(), //开始时
      oldm: Number(), //开始分钟
      olds: Number(), //开始秒
      newH: Number(), //结束时
      newm: Number(), //结束分钟
      news: Number(), //结束秒
      list: [], //时间列表
    };
  },
  methods: {
    time(val) {
      console.log(val);
      console.log(this.value1);
      //开始时分秒
      this.oldH = val[0].slice(0, 2);
      this.oldm = val[0].slice(3, 5);
      this.olds = val[0].slice(6, 8);
      this.newH = val[1].slice(0, 2);
      this.newm = val[1].slice(3, 5);
      this.news = val[1].slice(6, 8);
      console.log(this.oldH);
    },
    add() {
      let data = {
        id: 0,
        startHour: this.oldH,
        startMinute: this.oldm,
        startSecond: this.olds,
        endHour: this.newH,
        endMinute: this.newm,
        endSecond: this.news,
      };
      AddOrEdit(data)
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
              this.$message("添加成功");
              this.qgetlist()
          } else if (res.code === -1) {
            this.$message("时间不能重复");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取时间列表
    qgetlist() {
      GetList()
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.list = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dele(val) {
      let data = {
        id: val,
      };
      // console.log(val);
      Delete(data)
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.$message("删除成功");
            this.qgetlist();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.value1 = "";
    this.qgetlist();
  },
};
</script>

<style scoped>
.setke-continer {
  width: 100%;
  height: 100%;
  background: white;
}
.setke-center {
  margin-top: 8px;
  overflow: hidden;
}
.call {
  width: 505px;
  height: 40px;
  margin-left: 50px;
  margin-top: 40px;
}
.text {
  float: left;
  margin-top: 10px;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-right: 10px;
}
.inp {
  float: left;
}
.ico {
  float: left;
  margin-left: 22px;
  margin-top: 10px;
}
.end {
  width: 432px;
  height: 200px;
  margin-top: 11px;
  margin-left: 134px;
}
.list {
  width: 81%;
  height: 24px;
  border: 1px solid #d9d9d9;
  float: left;
  margin-bottom: 10px;
}
.list-left {
  float: left;
}
.list-center {
  float: left;
  margin-left: 43px;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cccccc;
  line-height: 24px;
}
.his {
  float: left;
  margin-left: 27px;
}
</style>